import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {signIn, signUp} from '../routes/UserAction';

import CustomAlert from '../components/Alert/CustomAlert';
import TermsModal from '../components/Modal/TermsModal'; 

import "./home.css"
import PageHeaderTwo from "./PageHeaderTwo"
import PageHeader from "./PageHeader"

import ErrorDialog from '../components/Alert/ErrorDialog';
import PushNotification from '../components/Alert/PushNotification';
import MapSetter from '../components/Map/MapSetter';
import { domainUrl } from '../dummyData';

const RegisterPage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalRef = useRef()
  const handleOpen = () => {
    modalRef.current.handleOpen();
      
  };

  const customAlertRef = useRef()
  const errorDialogRef = useRef()
  const mapDialog = useRef()

  const [stage, setStage] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleRememberMe = () => {
    setFormData({...formData, rememberMe:!rememberMe});
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    voterId: "",
    lat: "",
    long: '',
    phone: '',
    password: '',
    email: '',
    term: false,
    rememberMe: false
  });

  const handleChangeTerm = () => {
    setFormData({...formData, term:term===0?1:0});
  }

  const handleResponse = (value) => {
    if (value) {
      setFormData({...formData, lat:value.lat, long:value.lng });
    } else {
      setFormData({...formData, lat:'', long:'' });
    }
    
  }

  const {
    name,
    voterId,
    email, 
    password,
    long, 
    lat, 
    phone,
    term,
    rememberMe
  } = formData;

  const handleChange = (e) => {
    // if (e.target.name ==== 'userType') {
    //   setFormData({ ...formData, [e.target.name]: e.target.checked? 4: 3 });
    // } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
  };

  const clearData = () => {
    setFormData({ 
      ...formData, 
      name: '',
      voterId: '',
      email: '',
      phone: '',
      password: '',
      lat: '',
      long: '',
      term: false,
      rememberMe: false
    });
  };

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
    if (userToken !== null) {
      navigate('/landing_page')
      // return navigateToRoute('login')
      // window.location.href = "/login";
      // return null
    }
    // fetchExpertiseData();

    // if (user) { 
    //   navigate('/landing_page') 
    // } else {
    //   fetchExpertiseData();
    // }

      // if (success) navigate('/message')
  }, [navigate, user, success])

  const checkPassword = (val) => {
    let status = false;
    if (val.length > 0) {
      return false
    } 
  }

  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    // PushNotification(formData)
    if (password.length > 7) {
      if (
        email !== '' && voterId !== '' && password !== '' &&
        name !== '') 
      {
        // console.log('reg', formData)
        dispatch(signUp(formData))
        .then(response =>{
          setSubmitting(true);
          if (error) {
            setErrorMsg(error);
            customAlertRef.current.handleOpen(error, 'danger');
            setSubmitting(false);
            
          } else {
            if (successMessage !== '') {
              dispatch(signIn({email:email, password:password}))
              .then(response =>{
                customAlertRef.current.handleOpen(successMessage, 'success');
                setSubmitting(false);
                navigate('/landing_page')
              })
            }
          }
        }).catch((error) => {
          setSubmitting(false);
          customAlertRef.current.handleOpen(error, 'danger');
        });
        
      } else {
        customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
        setSubmitting(false);
      }
    } else {
      customAlertRef.current.handleOpen('Password cannot be less than 8 characters!', 'danger');
      setSubmitting(false);
      
    }
  };

  return (
    <>
      
      {/* <ErrorDialog ref={errorDialogRef} message={errorMsg}/> */}

      {/* reg */}
      <div style={{display:stage?'block':'none', height:'100vh'}} className=''>
        <TermsModal 
          title={' Terms of service'} 
          ref={modalRef}
        />
        <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>

        <div className="main-box b-screen pb-4 overflow-y-auto" style={{backgroundColor:'#ec2f2f', height:'100vh'}}>

          <PageHeaderTwo screen={'big'}/>

          {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
                {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
            </div>
          ) :
          <div className="d-flex justify-content-center mb-5">
            <div className="rounded-4 d-flex flex-column justify-content-center my-auto pb-2" style={{width:'40vw', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
              <img alt='ddk' src="./assets/images/logo/kickball.png" className="rounded-circle mx-auto mt-2 mb-1" height="50" width="50"></img>
              <h6 style={{color:'#ec2f2f'}} className="mx-auto mt-0 mb-1">User Account</h6>
              
              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4">
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={name} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="name" id="name" autoComplete="name" className="form-control" placeholder="Full Name"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={voterId} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="voterId" id="voterId" className="form-control" placeholder="Voter ID"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone No"></input>
                    </div>
                  </div>
                </div>

                
                <div className="input-group input-group-sm my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                    <i className="fa fa-lock"></i>
                  </span>
                  <input required onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                    {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                    :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                  </span>
                </div>

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#ec2f2f'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={term === 0? false: true} onChange={handleChangeTerm} value={term}/>
                  <h6 onClick={handleOpen} className='my-0' style={{color:'#ec2f2f', fontSize:11}}>I have read and accept all of the Terms of Service <i style={{color: '#F8BC16'}} className='fa fa-arrow-right'></i></h6>
                </div>

                <button
                  disabled={term === 0? true: submitting}
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #ec2f2f"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">
                    Sign up
                </button>
              </form>
            
            </div>
          </div>}

        </div>

        {/* mobile */}
        <div className="main-box m-screen pb-2 overflow-y-auto" style={{backgroundColor:'#ec2f2f', height:'100vh'}}>

          <PageHeaderTwo screen={'mobile'}/>

          {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
                {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
            </div>
          ) :
          <div className="d-flex justify-content-center my-5 px-3">
            <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-auto" style={{width:'100%', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
              <img alt='ddk' src="./assets/images/logo/holdball.png" className="rounded-circle mx-auto mt-2 mb-1" height="50" width="50"></img>
              <h6 style={{color:'#ec2f2f'}} className="mx-auto mt-0 mb-1">User Account</h6>
              
              {/* <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/> */}
              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-3">
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={name} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="name" id="name" autoComplete="name" className="form-control" placeholder="Full Name"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={voterId} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="voterId" id="voterId" autoComplete="voterId" className="form-control" placeholder="Voter ID"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone No"></input>
                    </div>
                  </div>
                </div>

                
                <div className="input-group input-group-sm my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                    <i className="fa fa-lock"></i>
                  </span>
                  <input onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #ec2f2f", color:'#ec2f2f'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #ec2f2f"}} className="input-group-text text-white">
                    {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                    :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                  </span>
                </div>
                

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#ec2f2f'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={term === 0? false: true} onChange={handleChangeTerm} value={term}/>
                  <h6 onClick={handleOpen} className='my-0' style={{color:'#ec2f2f', fontSize:11}}>I have read and accept all of the Terms of Service <i style={{color: '#F8BC16'}} className='fa fa-arrow-right'></i></h6>
                </div>

                <button 
                  disabled={term === 0? true: submitting}
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #ec2f2f"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">
                    Sign up
                  </button>
              </form>
            
            </div>
          </div>}

        </div>
      </div>
    </>
  )
}

export default RegisterPage
